import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["product", "startup", "research"],
  "status": ["completed"],
  "title": "Share Chair",
  "subtitle": "",
  "description": "",
  "years": ["2015"],
  "institutions": ["aau-cph", "roskilde festival"],
  "location": "cph",
  "keywords": {
    "topics": [],
    "tools": []
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }, {
    "name": "Gunnar",
    "role": "Designer"
  }, {
    "name": "Malte",
    "role": "Designer"
  }, {
    "name": "...",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Share Chair */
    }
    <h2>{`Description`}</h2>
    <p>{`At Roskilde Festival, almost everyone brings an exemplar of the popular camping chair. They almost all break, within a few days if not taken care of properly. And most of them are left behind for the volunteers to clean them up as trash.
A product service concept called Share Chair.
Camping chair service at Roskilde Festival.
Chair rental and repair at Roskilde Festival, in order to try and recollect trash before people leave the area.
Project at Roskilde Festival.
Festival chair sharing economy at Roskilde Festival 2015.
Disrupting the use-and-throw-away culture at Roskilde Festival by introducing circular economy through a product-service system for festival chairs. Opbrud med “brug-og-smid-væk” kulturen på Roskilde Festival ved at introducere et nyt circulært, gennem reperation, produkt-service system for festivalstole.
Disruption of “use-and-throw-away” culture at Roskilde Festival by introducing a new circular, by reparation, product-service system for festival chairs.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Photos`}</li>
      <li parentName="ul">{`Report`}</li>
      <li parentName="ul">{`'Projekt Tidslinje'`}</li>
      <li parentName="ul">{`Presentation Slides`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      